import { Component, OnInit } from '@angular/core';
import { CookiesLocation, ICookies, CCoockiesMessage, ICoockiesService } from '../types/ICookies'

@Component({
  selector: 'app-cookies-modal',
  templateUrl: './cookies-modal.component.html',
  styleUrls: ['./cookies-modal.component.css']
})
export class CookiesModalComponent implements OnInit {
  public cookies: ICookies;
  private cookiesMessage: ICoockiesService;

  constructor() {
    this.cookies = {
      location: CookiesLocation.location,
      value: false
    };

    this.cookiesMessage = new CCoockiesMessage();
  }

  ngOnInit(): void {
    this.cookies.value = this.cookiesMessage.get();
  }

  acceptCookies(): void {
    this.cookies.value = true;
    this.cookiesMessage.set(this.cookies.value);
  }

}
