import { Component, OnInit } from "@angular/core";
import { FiltersService } from "../../pages/filters/filters.service";
import { LocalService } from "../../services/local/local.service";
import { SessionService } from "../../services/session/session.service";
import { DatePipe } from "@angular/common";
import { ErrorService } from "../../services/error/error.service";
import { UrisService } from "../../services/uris/uris.service";
import { SubscriptionsService } from "../../services/subscriptions/subscriptions.service";

@Component({
    selector: "app-modals",
    templateUrl: "./modals.component.html",
    styleUrls: ["./modals.component.css"],
})
export class ModalsComponent implements OnInit {
    // open filters
    public names = [];
    public originalFilters = [];
    public deleteFieldView = "";
    public updateFieldView = "";
    public filter_name = "";
    public filter = "";
    private db = null;
    private fire = null;
    constructor(
        private filters: FiltersService,
        public local: LocalService,
        private session: SessionService,
        private datePipe: DatePipe,
        private error: ErrorService,
        private uris: UrisService,
        private subscriptions: SubscriptionsService
    ) {
        filters.reloadFiltersCalled$.subscribe(() => {
            this.loadViewsInit();
        });
        this.db = session.getDB();
        this.fire = session.getFire();
    }

    ngOnInit(): void {
        this.loadViewsInit();
    }

    // show modal for delete field in firestore
    deleteViewModal(name: string) {
        this.deleteFieldView = name;
        $("#load_view_modal")["modal"]("hide");
        $("#delete_view_modal")["modal"]("show");
    }

    // delete filter in firestore db
    deleteViewFilter() {
        this.filters.deleteField(this.deleteFieldView, () => {
            $("#delete_view_modal")["modal"]("hide");
            this.loadViews();
            if (
                this.filters.objFilter[this.deleteFieldView]["str"] ===
                this.local.dashboard.filter["str"]
            ) {
                this.filters.eraserFilter();
            }
        });
    }

    // load filters names in collapsed
    loadViews(callback = () => {}) {
        this.filters.get().subscribe(
            (doc: any) => {
                if (doc["exists"]) {
                    const tmp = Object.keys(doc.data());
                    const tmpFilLoad = doc.data();

                    // filters migration
                    const filLoad = {};

                    tmp.map((val) => {
                        if (tmpFilLoad[val].objUsersRes)
                            filLoad[val] = tmpFilLoad[val];
                    });
                    const names = Object.keys(filLoad);

                    this.names = names.sort((a, b) => {
                        return a.localeCompare(b);
                    });
                    this.originalFilters = names;

                    this.filters.names = names;
                    this.filters.objFilter = filLoad;
                    if (callback) callback();
                }
            },
            (err: any) => {
                this.error.init(err);
            }
        );
    }

    // check filters in firestore init
    loadViewsInit() {
        if (this.session.check)
            if (Object.keys(this.filters.objFilter).length === 0)
                this.loadViews();
    }

    // load filter in dashboard
    load(name: string) {
        this.filters.loadDash(name);
        this.filters.hideModal("load_view_modal");
        this.resetSearch();
    }

    // delete filters
    deleteFilter() {
        this.filters.eraserFilter();
    }

    // update and add filter in firestore
    update(name: string) {
        const value = this.local.dashboard.filter;

        this.filters
            .update(name, value)
            .then(() => {
                this.loadViews(() => {
                    this.filters.path = "/use";
                    this.filters.loadDash(name);
                });
                this.filters.hideModal();
            })
            .catch((err: any) => {
                if (err["code"] === "not-found") {
                    const obj = {
                        [name]: value,
                    };
                    // set new filter
                    this.filters
                        .set(obj)
                        .then(() => {
                            this.loadViews(() => {
                                this.filters.loadDash(name);
                            });
                            this.filters.hideModal();
                        })
                        .catch((err: any) => {
                            console.log(err);
                        });
                } else console.log(err);
            });
    }

    // save filter
    saveFilter(filterName: string) {
        const date = new Date();
        const tranform = this.datePipe.transform(date, "MMM d yy h:mm a'");
        const name = (filterName || tranform).split(".").join("");
        this.filter_name = "";

        if (this.filters.objFilter[name]) {
            this.updateFieldView = name;
            $("#update_view_modal")["modal"]("show");
            this.filters.path = "/use";
            this.filters.loadDash(name);
        } else {
            this.filters.path = "/use";
            this.update(name);
        }
    }

    // update filter
    updateViewFilter() {
        this.update(this.updateFieldView);
        $("#update_view_modal")["modal"]("hide");
    }
    search(filter) {
        let tmp = [];
        this.originalFilters.forEach((val) => {
            if (val.toLowerCase().includes(filter.toLowerCase())) {
                tmp.push(val);
            }
        });
        this.names = tmp;
    }
    resetSearch() {
        this.names = this.originalFilters;
        this.filter = "";
    }
    // confirm the comment
    callConfirmationChartComment(action) {
        if (this.local.subscriptions.comments) {
            let validationFlag = true;
            //dateValidation
            if (
                this.local.chartCommentModal.date == null ||
                this.local.chartCommentModal.date == ""
            ) {
                this.local.chartCommentModal.dateValid = false;
                validationFlag = false;
            }
            //commentValidation
            if (
                this.local.chartCommentModal.comment.length >
                this.local.chartCommentModal.commentMaxLength
            ) {
                validationFlag = false;
                this.local.chartCommentModal.commentEmpty = false;
                this.local.chartCommentModal.commentLengthValid = false;
            } else {
                if (this.local.chartCommentModal.comment.length === 0) {
                    this.local.chartCommentModal.commentEmpty = true;
                    validationFlag = false;
                } else {
                    this.local.chartCommentModal.commentEmpty = false;
                }
            }

            if (validationFlag) {
                this.local.chartCommentModal.commentEmpty = false;
                this.local.chartCommentModal.commentLengthValid = true;
                if (action === "add") {
                    $("#add_comment_modal")["modal"]("hide");
                    $("#update_comment_modal")["modal"]("hide");
                    $("#confirm_comment_modal")["modal"]("show");
                } else if (action === "update") {
                    $("#confirm_update_comment_modal")["modal"]("show");
                    $("#add_comment_modal")["modal"]("hide");
                    $("#update_comment_modal")["modal"]("hide");
                }
            }
        } else {
            $("#marketingSubscription")["modal"]("show");
            $("#add_comment_modal")["modal"]("hide");
            $("#update_comment_modal")["modal"]("hide");
        }
    }
    // reset Comment Modal Form
    resetCommentModal() {
        this.local.chartCommentModal.date = null;
        this.local.chartCommentModal.comment = "";
        this.local.chartCommentModal.charIndex = null;
        this.local.chartCommentModal.reference = null;
        this.local.chartCommentModal.commentLengthValid = true;
        this.local.chartCommentModal.commentEmpty = false;
        this.local.chartCommentModal.dateValid = true;
    }
    //save chart comment
    saveChartComment() {
        const date = this.local.chartCommentModal.date;
        const comment = this.local.chartCommentModal.reference;
        const chart = this.local.chartCommentModal.charIndex;
        const title = this.local.chartCommentModal.comment;
        let tmp = new Object();
        tmp[date] = { title: title, comment: comment };
        const collection = this.session
            .getDB()
            .collection(
                `Domain/${this.session.getDomain()}/ChartComments-${
                    this.uris.Service
                }/`
            )
            .doc(chart);

        collection
            .update(tmp)
            .then(() => {})
            .catch((err) => {
                if (err.code == "not-found")
                    collection
                        .set(tmp)
                        .then(() => {})
                        .catch((err1) => {
                            console.log(err1);
                        });
            });
        this.updateCharts(chart);

        this.resetCommentModal();
        $("#confirm_comment_modal")["modal"]("hide");
        $("#confirm_update_comment_modal")["modal"]("hide");
    }
    updateCharts(chart) {
        switch (chart) {
            case "chartGSuite":
                this.local.adoptionReloadChart();
                break;
            case "chartClTch":
                this.local.adoptionReloadChartCltch();
                break;
            case "chartRoleMeet":
                this.local.meetReloadCharts();
                break;
            case "chartDevicesMeet":
                this.local.meetReloadCharts();
                break;
            case "chartAccount":
                this.local.useReloadCharts();
                break;
            case "chartClassroom":
                this.local.useReloadCharts();
                break;
            case "chartDrive":
                this.local.useReloadCharts();
                break;
            case "chartDocs":
                this.local.useReloadCharts();
                break;
            case "chartDrawings":
                this.local.useReloadCharts();
                break;
            case "chartForms":
                this.local.useReloadCharts();
                break;
            case "chartSheet":
                this.local.useReloadCharts();
                break;
            case "chartSlide":
                this.local.useReloadCharts();
                break;
            case "chartSites":
                this.local.useReloadCharts();
                break;
            case "chartChromebookC":
                this.local.useReloadCharts();
                break;
            case "chartChromebookH":
                this.local.useReloadCharts();
                break;
            case "driveStorage":
                this.filters.reloadDashStorage();
                break;
        }
    }
    callConfirmationDeleteComment() {
        $("#confirm_delete_comment_modal")["modal"]("show");
        $("#add_comment_modal")["modal"]("hide");
        $("#update_comment_modal")["modal"]("hide");
    }
    findDate() {
        const commentCollection = this.db
            .collection(
                `Domain/${this.session.getDomain()}/ChartComments-${
                    this.uris.Service
                }/`
            )
            .doc(this.local.chartCommentModal.charIndex)
            .get()
            .subscribe((snapshot: any) => {
                const commentsData = snapshot.data();
                //console.log(commentsData);
                if (typeof commentsData !== "undefined") {
                    if (
                        typeof commentsData[
                            this.local.chartCommentModal.date
                        ] !== "undefined"
                    ) {
                        this.local.chartCommentModal.comment =
                            commentsData[
                                this.local.chartCommentModal.date
                            ].title;
                        this.local.chartCommentModal.reference =
                            commentsData[
                                this.local.chartCommentModal.date
                            ].comment;
                        this.local.chartCommentModal.modalTitle =
                            "Editar comentario";
                        this.local.chartCommentModal.modalButton = "Cambiar";
                    } else {
                        this.local.chartCommentModal.comment = "";
                        this.local.chartCommentModal.reference = "";
                        this.local.chartCommentModal.modalTitle =
                            "Agregar comentario";
                        this.local.chartCommentModal.modalButton = "Agregar";
                    }
                }
            });
    }
    deleteChartComment(name, callback) {
        const chart = this.local.chartCommentModal.charIndex;
        const date = this.local.chartCommentModal.date;
        console.log(date);
        const collection = this.db
            .collection(
                `Domain/${this.session.getDomain()}/ChartComments-${
                    this.uris.Service
                }/`
            )
            .doc(chart);

        collection
            .update({
                [date]: this.fire.firestore.FieldValue.delete(),
            })
            .then(() => {
                this.updateCharts(chart);
                this.resetCommentModal();
                $("#confirm_delete_comment_modal")["modal"]("hide");
            })
            .catch((err) => {
                console.log(err);
            });
    }
    public notify_subs(): void {
        this.subscriptions.subscriptionRequest(
            this.session.getDomain(),
            this.session.credential.user
        );
    }
}

