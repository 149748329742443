import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import {
  gtmEvent,
  IAnalyticsGTMEvent
} from '../../shared/types/IAnalyticsService';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private id: string;

  // Class Constructor
  constructor(
    private gtmService: GoogleTagManagerService,
    private gaService: GoogleAnalyticsService
  ) {
    // Start App message - by Google Tag Manager
    let event = {} as IAnalyticsGTMEvent;
    event.event = gtmEvent.START;
    this.gtmService.pushTag(event);
  }

  /**
   * Set id constructor
   */
  public setId(id=""): void {
    this.id = id;
  }

  /** - by Google Analytics -
   * Page navigation event
   */
  public page(page: string, title=""): void {
    this.gaService.pageView( page, title, "auto", {
      user_id: this.id
    });
  }

  /** - by Google Analytics -
   * Event assigned by code
   */
  public event(name: string, event: string, value=""): void {
    this.gaService.event(event, name, value);
  }
}
