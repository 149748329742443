import { Injectable } from '@angular/core';
import { SessionService } from '../session/session.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private session: SessionService
  ) {}

  init(err: any) {
    const code = err["status"];

    switch (code) {
      case 403:
        this.forbidden();
        break;
      case 418:
        this.teteapot();
        break;
      case 200:
        console.log("Ok");
        break;

      default:
        console.error(err["message"]);
        break;
    }
  }

  forbidden() {
    this.session.cleanSession(false);
  }

  teteapot() {
    this.session.cleanSession(false);
  }
}
