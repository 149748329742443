export enum CookiesLocation {
  location = "analytics_cookies"
}

export interface ICookies {
  location: CookiesLocation,
  value: boolean
}

export class CCoockiesMessage {
  set(value: boolean = false): void {
    localStorage.setItem(CookiesLocation.location, JSON.stringify(value));
  }

  get(): boolean {
    const value = JSON.parse(localStorage.getItem(CookiesLocation.location));
    if (value == null) return false;
    else return value;
  }
}

export interface ICoockiesService {
  set(value: boolean): void;
  get():boolean;
}
