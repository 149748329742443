import { HttpClientModule } from '@angular/common/http';

import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';

import * as $ from "jquery";

import { NgModule, LOCALE_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { ToastrModule } from "ngx-toastr";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SidebarModule } from './sidebar/sidebar.module';
import { ModalsModule } from './shared/modals/modals.module';
import { FooterModule } from './shared/footer/footer.module';
import { CookiesModalModule } from './shared/cookies-modal/cookies-modal.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { QuickstartModule } from "./shared/quickstart/quickstart.module";
import { FixedPluginModule } from './shared/fixedplugin/fixedplugin.module';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';

import { AngularFireModule } from '@angular/fire';

import { environment } from '../environments/environment';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/es';
registerLocaleData(localeFr);


@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {
    useHash: false,
    relativeLinkResolution: 'legacy'
}),
    AngularFireModule.initializeApp(environment.firebase),
    SidebarModule,
    NavbarModule,
    ModalsModule,
    ToastrModule.forRoot(),
    FooterModule,
    FixedPluginModule,
    QuickstartModule,
    NgxGoogleAnalyticsModule.forRoot( environment.googleAnalytics.trackingCode ),
    GoogleTagManagerModule.forRoot({ id: environment.googleAnalytics.tagManager }),
    HttpModule,
    HttpClientModule,
    RouterModule,
    CookiesModalModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-MX' },
    { provide: 'googleTagManagerCSPNonce', useValue: 'CSP-NONCE' },
    NgbActiveModal,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
