// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBieLSyugXPPPuNrVpbzK0xKVDoKl9JmfU",
    authDomain: "platmos.firebaseapp.com",
    databaseURL: "https://platmos.firebaseio.com",
    projectId: "platmos",
    storageBucket: "platmos.appspot.com",
    messagingSenderId: "514709670251",
    appId: "1:514709670251:web:b80d5339ec1c3b9055e42e"
  },
  googleAnalytics: {
    trackingCode: 'UA-152864242-1',
    tagManager: 'GTM-5XP6FZJ'
   }
};
