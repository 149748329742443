import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms'
import { QuickstartComponent } from "./quickstart.component";

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule
  ],
  declarations: [ QuickstartComponent ],
  exports: [ QuickstartComponent ]
})

export class QuickstartModule {}
