import { Injectable } from "@angular/core";
import { LocalService } from "../../services/local/local.service";
import { HttpService } from "../http/http.service";
import { AuthService } from "../auth/auth.service";

interface ISubscription {
    start_date: number,
    renovation_date: number,
    status: boolean,
    ammount?: number
}

@Injectable({
    providedIn: "root",
})
export class SubscriptionsService {
    public state: ISubscription;
    private db: any = null;
    private card_structure = [
        {
            header: {
                title: "Analytics Plus",
                subtitle: "",
                imageUrl: "https://analytics.edvolution.io/assets/img/icon.png",
                imageStyle: "IMAGE",
            },
            sections: [
                {
                    widgets: [],
                },
            ],
        },
    ];

    constructor(
        public local: LocalService,
        private http: HttpService,
        private auth: AuthService
    ) {
        this.db = this.auth.getDb();
        this.state = {
            start_date: 100000,
            renovation_date: 100000,
            status: false,
            ammount: 0
        }
    }

    /**
     * Set validation subscription to local service
     * @param {String} domain
     * @param {Object} db
     */
    public subscriptionValidation(domain: string, db: any): void {
        try {
            db.collection(`Platmos/Permit/Subscriptions/`)
                .doc(domain)
                .get()
                .subscribe(
                    (snapshot: any) => {
                        const data = snapshot.data();
                        if (snapshot.exists) {
                            for (const key in data) {
                                const status = data[key].status;
                                if (status) {
                                    //subscription vigente
                                    this.local.subscriptions[key] = true;
                                } else {
                                    //subscription vencida
                                    this.local.subscriptions[key] = false;
                                }
                            }

                            // Subscription state
                            this.state = {
                                start_date: data.driveStorage.start,
                                renovation_date: data.driveStorage.expire,
                                status: data.driveStorage.status,
                                ammount: data.driveStorage.ammount || 0
                            }
                            
                            // Banner show subscription
                            if (!this.local.subscriptions.driveStorage) {
                                this.showPlusBanner();
                            }
                        }
                    },
                    (err: any) => {
                        console.log(err);
                    }
                );
            }
            catch(err) {
                console.error(err);
            }
    }

    /**
     * Subscription validation per component
     * @param {String} domain 
     * @param {Object} db 
     * @param {String} component 
     * @returns {Promise} 
     */
    public async subscriptionValidationComponent(
        domain: string,
        db: any,
        component: string
    ): Promise<any> {
        try {
            const promesa = new Promise((resolve) => {
                db.collection(`Platmos/Permit/Subscriptions/`)
                    .doc(domain)
                    .get()
                    .subscribe(
                        (snapshot: any) => {
                            const data = snapshot.data();
                            if (snapshot.exists) {
                                resolve(data[component].status);
                            } else {
                                resolve(false);
                            }
                        },
                        (err: any) => {
                            console.log(err);
                        }
                    );
            });
            return await promesa;
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Subscription request
     * Only one request per domain
     * @param {String} domain
     * @param {String} email
     * @param {String} subtitle
     * @param {String} notify Object name to verification
     */
    public subscriptionRequest(
        domain: string, 
        email: string, 
        subtitle:string = "Solicitud de Suscripción", 
        notify: string = "notify"
    ): void {
        const widgets = [
            { keyValue: { icon: "STORE", content: domain } },
            { keyValue: { icon: "EMAIL", content: email } },
            { keyValue: { icon: "INVITE", content: new Date().toString() } },
        ];
        let body = { cards: this.card_structure };
        body.cards[0].header.subtitle = subtitle;
        body.cards[0].sections[0].widgets = widgets;

        const tmp = {};
        tmp[notify] = true;
        tmp[notify + "User"] = email;

        const snapshot = this.db
            .collection(`Domain/${domain}/Settings/`)
            .doc("SubsNotify");
        snapshot.get().subscribe((response: any) => {
            if (response.exists) {
                const data = response.data();

                if (!data[notify]) {
                    this.http.uri_serve = "URI_aps_notifications";
                    this.http.sPost("&threadKey=" + domain, body);

                    snapshot.update(tmp);
                }
            } else {
                this.http.uri_serve = "URI_aps_notifications";
                this.http.sPost("&threadKey=" + domain, body);

                snapshot.set(tmp);
            }
        });
        $("#notifySuscriptionRequest")["modal"]("show");
        $("#marketingSubscription")["modal"]("hide");
    }

    /**
     * Banner subscription on local storage status
     */
    public showPlusBanner(): void {
        if (!$("#quickstart-cmp-modal").is(":visible")) {
            if (!localStorage.analyticsPlus) {
                if (!this.local.subscriptions.driveStorage) {
                    $("#marketingSubscription")["modal"]("show");
                }
                localStorage.analyticsPlus = true;
            }
        }
    }

    /**
     * Actual date on mills
     * @returns number
     */
    public dateNow(): number {
        return new Date().getTime();
      }
}

