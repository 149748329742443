import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { UrisService } from '../uris/uris.service';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private srv = null;

  constructor(
    private http: HttpService,
    private uris: UrisService
  ) {
    this.srv = uris.Service;
  }

  save(event, email, message, callback=()=>{}) {
    const values = [
      `type=${event}`,
      `email=${email}`,
      `message=${this.srv}:${message}`
    ];

    this.http.uri_serve = "URI_back";
    this.http.sGet("logger", values)
      .then(rs => {
        if (callback) callback();
      }).catch(err => {
        console.log(err)
        if (callback) callback();
      });
  }
}
