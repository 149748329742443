import {
    Component,
    OnInit,
    Renderer2,
    ViewChild,
    ElementRef,
} from "@angular/core";
import { SessionService } from "../../services/session/session.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { FilterService } from "../../services/filter/filter.service";

@Component({
    moduleId: module.id,
    selector: "navbar-cmp",
    templateUrl: "navbar.component.html",
})
export class NavbarComponent implements OnInit {
    private listTitles: any[];
    public mask = false;
    location: Location;
    private nativeElement: Node;
    private toggleButton;
    private sidebarVisible: boolean;
    public domains: any[];
    public userInfo = {};
    public domainFilter = "";

    public orgList = [];

    public isCollapsed = true;
    @ViewChild("navbar-cmp", { static: false }) button;

    constructor(
        location: Location,
        private renderer: Renderer2,
        private element: ElementRef,
        private router: Router,
        public session: SessionService,
        public filter: FilterService
    ) {
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
        this.domains = session.getDomains();
        this.userInfo = session.getSession()["userinfo"];
    }

    ngOnInit() {
        this.loadTitles();
        this.loadDomains();
    }

    // Navar Controller
    loadTitles() {
        this.listTitles = this.session.nav_show;
        var navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName("navbar-toggle")[0];
        this.router.events.subscribe((event) => {
            this.sidebarClose();
        });
    }
    getTitle() {
        let titlee = this.location.prepareExternalUrl(this.location.path());

        if (titlee.charAt(0) === "#") {
            titlee = titlee.slice(1);
        }
        for (var item = 0; item < this.listTitles.length; item++) {
            if (this.listTitles[item].path === titlee) {
                return this.listTitles[item].navTitle;
            }
        }
        return "";
    }
    sidebarToggle() {
        if (this.sidebarVisible === false) this.sidebarOpen();
        else this.sidebarClose();
    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName("html")[0];
        const mainPanel = <HTMLElement>(
            document.getElementsByClassName("main-panel")[0]
        );
        setTimeout(function () {
            toggleButton.classList.add("toggled");
        }, 500);

        html.classList.add("nav-open");
        if (window.innerWidth < 991) {
            mainPanel.style.position = "fixed";
        }
        this.sidebarVisible = true;
    }
    sidebarClose() {
        const html = document.getElementsByTagName("html")[0];
        const mainPanel = <HTMLElement>(
            document.getElementsByClassName("main-panel")[0]
        );
        if (window.innerWidth < 991) {
            setTimeout(function () {
                mainPanel.style.position = "";
            }, 500);
        }
        this.toggleButton.classList.remove("toggled");
        this.sidebarVisible = false;
        html.classList.remove("nav-open");
    }
    collapse() {
        this.isCollapsed = !this.isCollapsed;
        const navbar = document.getElementsByTagName("nav")[0];

        if (!this.isCollapsed) {
            navbar.classList.remove("navbar-transparent");
            navbar.classList.add("bg-white");
        } else {
            navbar.classList.add("navbar-transparent");
            navbar.classList.remove("bg-white");
        }
    }

    // Domain Controller
    loadDomains() {
        this.orgList = this.session.getDomains().sort();
    }
    changeDomain(domain) {
        if (domain !== this.session.getDomain()) {
            const path_location = window.location.pathname;

            this.session.setDomain(domain);
            location.reload();
        }

        $("#domainsModal")["modal"]("hide");
    }
    showModalDomain() {
        this.mask = this.session.getUserInfo()["account"] || false;
        if (!this.mask) {
            this.loadDomains();
            $("#domainsModal")["modal"]("show");
        }
    }
    searchDomain(input = "") {
        const ins = input.toLowerCase();
        let org = new Array();

        this.session.getDomains().map((val) => {
            const parse = this.filter.parseDomainName(val).toLowerCase();
            if (parse.indexOf(ins) >= 0) org.push(val);
        });

        this.orgList = org;
    }

    logout() {
        this.session.cleanSession(false);
    }

    showHelp(): void {
        $("#quickstart-cmp-modal")["modal"]("toggle");
    }
}

