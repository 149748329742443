import { Component, HostBinding } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    moduleId: module.id,
    selector: "footer-cmp",
    templateUrl: "footer.component.html",
    styleUrls: ["./footer.component.css"],
})
export class FooterComponent {
    constructor(public router: Router) {}
    test: Date = new Date();
    showFeedbackModal(): void {
        $("#feedbackModal")["modal"]("show");
    }
}

