import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class UrisService {
    public HOME_PAGE = "/about";
    public LOGIN_PAGE = "/login";
    public HOME_LOGIN = "/use";

    // localhost or IP address
    private LocalHost = "localhost";

    private Host: string = "";
    public URI_back: string = "";
    public URI_front: string = "";
    public URI_extension: string = "";
    public URI_cp: string = "";
    public URI_mailer: string = "";
    public URI_chat: string = "";
    public URI_getURL: string = "";
    public URI_create: string = "";
    public URI_token: string = "";
    public URI_strapi: string = "";
    // Analytics plus notification chanel
    public URI_aps_notifications: string = "";
    public Service: string = "";

    constructor() {
        this.Host = window.location.hostname;
        const host = this.Host;
        const URI = this.generateUris(host);

        if (host.indexOf(this.LocalHost) >= 0) {
            this.URI_back = `http://${URI.back.dev}/`;
            this.URI_front = `http://${URI.front.dev}/`;
            this.URI_cp = `http://${URI.preamtible.dev}/`;
            this.URI_mailer = `https://${URI.mailer.dev}/`;
            this.URI_chat = `https://${URI.chat.dev}/`;
            this.URI_extension = `https://${URI.extension.dev}/`;
            this.URI_getURL = `https://${URI.getUrl.dev}/`;
            this.URI_create = `https://${URI.create.dev}/`;
            this.URI_token = `https://${URI.token.dev}/`;
            this.URI_strapi = `http://${URI.strapi.dev}/`;
            this.URI_aps_notifications = `https://${URI.aps.dev}`;
            this.Service = URI.service.dev;
        } else if (host.indexOf("labs") !== -1) {
            this.URI_back = `https://${URI.back.labs}/`;
            this.URI_front = `https://${URI.front.labs}/`;
            this.URI_cp = `https://${URI.preamtible.labs}/`;
            this.URI_mailer = `https://${URI.mailer.labs}/`;
            this.URI_chat = `https://${URI.chat.labs}/`;
            this.URI_extension = `https://${URI.extension.labs}/`;
            this.URI_getURL = `https://${URI.getUrl.labs}/`;
            this.URI_create = `https://${URI.create.labs}/`;
            this.URI_token = `https://${URI.token.labs}/`;
            this.URI_strapi = `https://${URI.strapi.labs}/`;
            this.URI_aps_notifications = `https://${URI.aps.labs}`;
            this.Service = URI.service.labs;
        } else if (host.indexOf("dev") !== -1) {
            this.URI_back = `https://${URI.back.stg}/`;
            this.URI_front = `https://${URI.front.stg}/`;
            this.URI_cp = `https://${URI.preamtible.stg}/`;
            this.URI_mailer = `https://${URI.mailer.stg}/`;
            this.URI_chat = `https://${URI.chat.stg}/`;
            this.URI_extension = `https://${URI.extension.stg}/`;
            this.URI_getURL = `https://${URI.getUrl.stg}/`;
            this.URI_create = `https://${URI.create.stg}/`;
            this.URI_token = `https://${URI.token.stg}/`;
            this.URI_strapi = `https://${URI.strapi.stg}/`;
            this.URI_aps_notifications = `https://${URI.aps.stg}`;
            this.Service = URI.service.stg;
        } else {
            this.URI_back = `https://${URI.back.prod}/`;
            this.URI_front = `https://${URI.front.prod}/`;
            this.URI_cp = `https://${URI.preamtible.prod}/`;
            this.URI_mailer = `https://${URI.mailer.prod}/`;
            this.URI_chat = `https://${URI.chat.prod}/`;
            this.URI_extension = `https://${URI.extension.prod}/`;
            this.URI_getURL = `https://${URI.getUrl.prod}/`;
            this.URI_create = `https://${URI.create.prod}/`;
            this.URI_token = `https://${URI.token.prod}/`;
            this.URI_token = `https://${URI.token.prod}/`;
            this.URI_strapi = `https://${URI.strapi.prod}/`;
            this.URI_aps_notifications = `https://${URI.aps.prod}`;
            this.Service = URI.service.prod;
        }
    }

    generateUris(host: string) {
        const port = window.location.port;
        const URI = {
            // Cloud unctions
            back: {
                // uri service backend Cloud Functions Request by hosting redirected
                // Firebase hosting *.web.app or *.firebaseapp.com
                prod: "us-central1-platmos.cloudfunctions.net/platmos/platmos",
                labs: "us-central1-platmos.cloudfunctions.net/labs/platmos",
                stg: "us-central1-platmos.cloudfunctions.net/dev/platmos",
                dev: "localhost:8000/platmos/us-central1/labs/platmos",
            },
            front: {
                prod: "analytics.edvolution.io",
                labs: "labs.analytics.edvolution.io",
                stg: "dev-analytics-edvolution.web.app",
                dev: `${host}:${port}`,
            },
            // Cloud functions
            extension: {
                prod: "analytics.edvolution.io/extension",
                labs: "labs.analytics.edvolution.io/extension",
                stg: "labs.analytics.edvolution.io/extension",
                dev: `${host}:8000`,
            },
            // preamtible compute engine
            preamtible: {
                prod: "miner.analytics.edvolution.io",
                labs: "miner.analytics.edvolution.io",
                stg: "miner.analytics.edvolution.io",
                dev: "localhost:8080",
            },
            getUrl: {
                prod: "get-url-dot-platmos.uc.r.appspot.com",
                labs: "get-url-dot-platmos.uc.r.appspot.com",
                stg: "get-url-dot-platmos.uc.r.appspot.com",
                dev: "get-url-dot-platmos.uc.r.appspot.com",
            },
            // Microservices app engine
            mailer: {
                prod: "mailer-dot-platmos.appspot.com",
                labs: "mailer-dot-platmos.appspot.com",
                stg: "mailer-dot-platmos.appspot.com",
                dev: "mailer-dot-platmos.appspot.com",
            },
            chat: {
                prod: "chat-dot-platmos.appspot.com",
                labs: "chat-dot-platmos.appspot.com",
                stg: "chat-dot-platmos.appspot.com",
                dev: "chat-dot-platmos.appspot.com",
            },
            // Service Create (project config)
            create: {
                prod: "create-dot-platmos.uc.r.appspot.com",
                labs: "create-dot-platmos.uc.r.appspot.com",
                stg: "create-dot-platmos.uc.r.appspot.com",
                dev: "create-dot-platmos.uc.r.appspot.com", //"localhost:8080"
            },
            token: {
                prod: "token-appspot.platmos.com",
                labs: "token-appspot.platmos.com",
                stg: "token-appspot.platmos.com",
                dev: "token-appspot.platmos.com", //"localhost:8080"
            },
            strapi: {
                prod: "api.my.edvolution.io",
                labs: "staging.api.my.edvolution.io",
                stg: "staging.api.my.edvolution.io",
                dev: "localhost:1337",
            },
            aps: {
                prod: "chat.googleapis.com/v1/spaces/AAAAdOILtg4/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=JwxunAtQM_NuYCa59FXt6xo62ZzOw1Tn4oE3Tc9owG8%3D",
                labs: "chat.googleapis.com/v1/spaces/AAAADDd_U4c/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=PXdh2Leimg3cyr-ggguxqogwMBmMBllnDkTdfvecyQY%3D",
                stg: "chat.googleapis.com/v1/spaces/AAAADDd_U4c/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=PXdh2Leimg3cyr-ggguxqogwMBmMBllnDkTdfvecyQY%3D",
                dev: "chat.googleapis.com/v1/spaces/AAAADDd_U4c/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=PXdh2Leimg3cyr-ggguxqogwMBmMBllnDkTdfvecyQY%3D",
            },
            service: {
                prod: "production",
                labs: "labs",
                stg: "dev",
                dev: this.LocalHost,
            },
        };

        return URI;
    }
}

