import { Injectable } from "@angular/core";
import * as firebase from "firebase/app";

import "firebase/auth";
import "firebase/firestore";

import {
    AngularFirestore,
    AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { auth } from "firebase/app";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    private ui = null;
    private db = null;
    private fire = null;

    constructor(
        private firestore: AngularFirestore,
        public authFire: AngularFireAuth
    ) {
        this.ui = this.authFire;
        this.db = this.firestore;
        this.fire = firebase;
    }

    public userUI(callback: any) {
        this.ui.onAuthStateChanged((user: any) => {
            window["user"] = user; // user is undefined if no user signed in
            callback(user);
        });
    }

    // Start auth session
    public start(callback = (err: any, res: any) => {}) {
        this.authFire
            .signInWithPopup(new auth.GoogleAuthProvider())
            .then((ret) => {
                callback(undefined, ret);
            })
            .catch((err) => {
                callback(err, undefined);
            });
    }

    // End auth session
    public end(callback = () => {}) {
        this.authFire.signOut().then(callback);
    }

    public getDb() {
        return this.db;
    }
    public getFire() {
        return this.fire;
    }

    public svSsn(access: any, callback = () => {}) {
        const inst = {
            token: access["userInfo"]["accessToken"],
            provider: access["userInfo"]["providerId"],
            expire_date: this.timeExpired()["timeout"],
            domains: access["domain"],
        };
        this.db
            .collection("Session")
            .doc(access["userInfo"]["email"])
            .set(inst)
            .then(() => {
                callback();
            })
            .catch((e: any) => {
                console.log(e);
            });
    }

    private timeExpired() {
        let timeExpired = {
            days: 3600000 * 6, // hours token expired
            day: new Date().getTime(), // date now in milliseconds
            timeout: 0,
        };
        timeExpired.timeout = timeExpired.days + timeExpired.day;
        return timeExpired;
    }
}

