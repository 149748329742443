import { Injectable } from "@angular/core";
import { title } from "process";
import { Subject } from "rxjs";
import * as namesArray from "./mask.json";
import * as ouArray from "./ou.json";

@Injectable({
    providedIn: "root",
})
export class LocalService {
    public days = 30;
    public daysCustom = {
        init: null,
        end: null,
    };
    public available_day = {
        dateInit: null,
        dateEnd: null,
    };
    public isCustom = false;
    public filter_apply = "";
    public dashboard = {
        phantom: {
            accounts: {},
            classroom: {},
            drive: {},
            docs: {},
            drawings: {},
            forms: {},
            sheet: {},
            slide: {},
            sites: {},
            chromebook: {},
            devicesMeet: {},
            roleMeet: {},
        },
        accounts: {},
        filter: {
            users: "",
            objUsersRes: [],
            lenUsers: 0,
            cb: "",
            objCbRes: [],
            lenCb: 0,
            org: "",
            objOrgRes: [],
            lenOrg: 0,
        },
    };
    public subscriptions = {
        driveStorage: false,
        clarep: false,
        comments: false,
    };
    public mask = {
        user: {},
        obj: null,
        ou: {},
        objOu: null,
    };
    public settings = {
        grs: null,
        customer: null,
    };
    public grsVision = false;
    // Adoption local modal
    public adp = {
        cbPercentage: null,
        classroomPercentage: null,
        gmailPercentage: null,
        drivePercentage: null,
        docsPercentage: null,
    };
    public chartCommentModal = {
        charIndex: null,
        date: null,
        comment: "",
        reference: null,
        commentLengthValid: true,
        commentMaxLength: 50,
        commentEmpty: false,
        dateValid: true,
        modalTitle: "Agregar comentario",
        modalButton: "Agregar",
    };
    public driveStorage = {
        totalUsed: null,
        drive: null,
        gmail: null,
        photos: null,
        shared: null,
    };
    public workingHost = "";
    public loadChartComment = {
        date: null,
        chart: null,
    };
    private driveReloadChartObserve = new Subject<any>();
    driveReloadChartCalled$ = this.driveReloadChartObserve.asObservable();
    //obsevable for adoption chart
    private adoptionReloadChartObserve = new Subject<any>();
    adoptionReloadChartCalled$ = this.adoptionReloadChartObserve.asObservable();
    //obsevable for adoption chart teachers
    private adoptionReloadChartClTchObserve = new Subject<any>();
    adoptionReloadChartClTchCalled$ =
        this.adoptionReloadChartClTchObserve.asObservable();
    //obsevable for meet charts
    private meetReloadChartRoleMeetObserve = new Subject<any>();
    meetReloadChartRoleMeetCalled$ =
        this.meetReloadChartRoleMeetObserve.asObservable();
    //obsevable for use charts
    private ReloadChartsUseObserve = new Subject<any>();
    ReloadChartsUsetCalled$ = this.ReloadChartsUseObserve.asObservable();
    constructor() {
        this.mask.obj = namesArray.names_mask;
        this.mask.objOu = ouArray.OU;
    }
    driveReloadChart() {
        this.ReloadChartsUseObserve.next();
    }
    adoptionReloadChart() {
        this.adoptionReloadChartObserve.next();
    }
    adoptionReloadChartCltch() {
        this.adoptionReloadChartClTchObserve.next();
    }
    meetReloadCharts() {
        this.meetReloadChartRoleMeetObserve.next();
    }
    useReloadCharts() {
        this.ReloadChartsUseObserve.next();
    }
    maskFilter(input, apply) {
        if (apply[0]) {
            if (apply.indexOf("name") >= 0) {
                if (this.mask.user[apply[2]])
                    return this.mask.user[apply[2]][1];
                else {
                    const ms = this.mask.obj.splice(0, 1)[0].split(",");
                    this.mask.user[apply[2]] = ms;
                    return this.mask.user[apply[2]][1];
                }
            } else if (apply.indexOf("email") >= 0) {
                if (this.mask.user[apply[2]])
                    return this.mask.user[apply[2]][2];
                else {
                    const ms = this.mask.obj.splice(0, 1)[0].split(",");
                    this.mask.user[apply[2]] = ms;
                    return this.mask.user[apply[2]][2];
                }
            } else if (apply.indexOf("ou") >= 0) {
                if (this.mask.ou[apply[2]]) return this.mask.ou[apply[2]];
                else {
                    const ms = this.mask.objOu.splice(0, 1)[0];
                    this.mask.ou[apply[2]] = ms;
                    return this.mask.ou[apply[2]];
                }
            } else return "Edvolution Demo";
        } else return input;
    }
}

