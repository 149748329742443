import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../auth/auth.service";
import { UrisService } from "../uris/uris.service";
import { AnalyticsService } from "../analytics/analytics.service";
import { HttpService } from "../http/http.service";
import { LoggerService } from "../logger/logger.service";
import { LocalService } from "../local/local.service";
import { Subject } from "rxjs";
import { SubscriptionsService } from "../subscriptions/subscriptions.service";
import * as encrypt from "sha1";
const sha1 = encrypt.default;

// ----------------------------------------------------------------------------
// Version app sidebar
const version_app = "3.1.2";
// ----------------------------------------------------------------------------

@Injectable({
    providedIn: "root",
})
export class SessionService {
    public app_version = version_app;
    public check = false;
    private session = {};
    public nav_show = [];
    private id = "";
    private domains = [];
    private domain = "";
    private userInfo = {};
    private collections = {
        login: "Login",
        timeout: "Timeout",
        admin: "Platmos",
    };
    public credential = {
        user: "",
        token: "",
    };
    public admin = false;
    public userTMP = {
        name: "",
        email: "",
    };
    public role = "user";
    private redirect = "";

    public modal = {
        title: "",
        body: "",
    };

    private routes_unapproval = [
        "login",
        "conditions",
        "privacy",
        "about",
        "register",
        "demo",
    ];
    private routes_not_admin = ["settings", "myedvolution"];

    // Observable string sources
    private loadNavObserve = new Subject<any>();
    componentMethodCalled$ = this.loadNavObserve.asObservable();

    private loginModalObserve = new Subject<any>();
    loginModalCalled$ = this.loginModalObserve.asObservable();
    private collectionNewsletter: string;

    constructor(
        private auth: AuthService,
        private route: Router,
        private uris: UrisService,
        private http: HttpService,
        private track: AnalyticsService,
        private local: LocalService,
        private logger: LoggerService,
        private subscriptions: SubscriptionsService
    ) {
        this.collectionNewsletter = "Platmos/Newsletter/Domains";

        const session = localStorage.session_token_analytics;
        this.redirect = window.location.pathname;
        if (this.redirect.indexOf("demo") >= 0)
            this.redirect = this.uris.HOME_LOGIN;

        if (session) {
            const parse = JSON.parse(session);
            this.session = parse;
            this.check = true;
            const id = parse["userInfo"]["email"];
            const token = parse["userInfo"]["accessToken"];
            this.credential.user = id;
            this.credential.token = token;
            this.id = id;
            this.userInfo = parse["userInfo"];
            this.domains = parse["domain"];
            this.role = parse["userInfo"]["role"];
            this.grs();
            track.setId(id);
        } else this.check = false;
    }

    loadNav() {
        this.loadNavObserve.next();
    }
    errorLoginModal() {
        this.loginModalObserve.next();
    }

    start(callback = null) {
        if (callback) this.auth.start(callback);
        else this.auth.start();
    }

    getSession() {
        return this.session;
    }
    getUserInfo() {
        return this.userInfo;
    }

    setSession(obj: any): void {
        const ss = JSON.stringify(obj);
        this.session = obj;
        localStorage.session_token_analytics = ss;
        if (localStorage.session_token_analytics) {
            const id = obj["userInfo"]["email"];
            const token = obj["userInfo"]["accessToken"];
            this.track.event("Login", `success`, id);
            this.id = id;
            this.track.setId(id);
            this.check = true;
            this.credential.user = id;
            this.credential.token = token;
            this.userInfo = obj["userInfo"];
            this.domains = obj["domain"];
            this.role = obj["userInfo"]["role"];

            let domain = "";
            let principal = false;

            this.domains.map((val) => {
                if (val.indexOf(this.userInfo["domain"]) == 0) {
                    domain = val;
                    principal = true;
                } else if (
                    val.indexOf(this.userInfo["domain"]) == 0 &&
                    !principal
                )
                    domain = val;
            });
            this.setDomain(domain);
            this.quickstartLoad();
        } else this.check = false;

        this.grs();
    }

    grs() {
        const db = this.auth.getDb();
        db.collection("Platmos")
            .doc("grs")
            .get()
            .subscribe(
                (val: any) => {
                    const doc = val.data();
                    let domain = this.getDomain() as string;
                    if (domain) domain = domain.split("_").join(".");

                    if (doc[domain]) this.local.grsVision = true;
                },
                (err: any) => {
                    console.log(err);
                }
            );
    }

    setDomain(domain: string): void {
        localStorage.session_token_analytics_domain = domain;
        this.domain = domain;
    }
    getDomain(): string {
        this.domain = localStorage.session_token_analytics_domain;
        return this.domain;
    }
    deleteDomain(): void {
        delete localStorage.session_token_analytics_domain;
    }

    getDB() {
        return this.auth.getDb();
    }
    getFire() {
        return this.auth.getFire();
    }

    getDomains(): Array<any> {
        return this.domains;
    }

    approval(): void {
        this.sideNav();

        // redirect homepage if role user
        const path_location = window.location.pathname.replace("/", "");

        if (
            this.routes_not_admin.indexOf(path_location) >= 0 &&
            this.role == "user"
        ) {
            this.route.navigate([this.uris.HOME_PAGE]);
        }

        let title = "";
        this.nav_show.map((val) => {
            if (val["path"].indexOf(path_location) !== -1) {
                title = val["title"];
            }
        });

        this.track.page(path_location, title);

        if (!this.check) {
            if (this.routes_unapproval.indexOf(path_location) === -1) {
                this.route.navigate([this.uris.LOGIN_PAGE]);
            }
            $(".sidebar").css("display", "none");
            $(".navbar").css("display", "none");

            $(".main-panel").css("width", "100%");
            $(".main-panel").css("height", "100%");
            $(".content").css("padding", "0 30px calc(10% - 50px)");
            $(".content").css("margin-top", "0px");
            $(".content").css("padding-bottom", "0 px");
        } else {
            this.recoveryDaysAvalilable();
            if (path_location.indexOf("login") > -1) {
                this.route.navigate([this.uris.HOME_PAGE]);
            }
            $(".sidebar").css("display", "block");
            $(".navbar").css("display", "flex");
            $(".main-panel").css("width", "");
            $(".main-panel").css("height", "");
            $(".content").css("margin-top", "83px");
        }
        this.subscriptions.subscriptionValidation(
            this.getDomain(),
            this.getDB()
        );
    }

    cleanSession(error = false): void {
        const errorObj = {};
        const id = this.id;
        if (error) {
            errorObj["pe"] = "true";
        }
        this.credential.token = null;
        this.credential.user = null;
        this.session = {};
        this.check = false;
        delete localStorage.session_token_analytics;
        this.auth.end();
        this.deleteDomain();
        if (error) {
            this.track.event("Login", `failure`);
            this.errorLoginModal();
            this.route.navigate([this.uris.LOGIN_PAGE]);
        } else {
            this.logger.save("login:logout", id, `logout success`);
            this.route.navigate([this.uris.LOGIN_PAGE]);
        }
    }

    sideNav(): void {
        const nav = [
            // { path: '/login',     title: 'Iniciar sesión', navTitle: 'Entrar',            icon: '',                 class: '', session_show: true },
            {
                path: "/use",
                title: "Uso",
                navTitle: "Metas de Uso",
                icon: "multiline_chart",
                class: "",
                session_show: false,
                id: "sidebar_use",
            },
            {
                path: "/adoption",
                title: "Adopción",
                navTitle: "Metas de Adopción",
                icon: "score",
                class: "",
                session_show: false,
                id: "sidebar_adoption",
            },
            /* {
                path: "/myedvolution",
                title: "My Edvolution",
                navTitle: "My Edvolution",
                icon: "myedvolution",
                class: "",
                session_show: false,
                id: "sidebar_myedvolution",
            }, */
            {
                path: "/meet",
                title: "Meet",
                navTitle: "Google Meet",
                icon: "videocam",
                class: "",
                session_show: false,
                id: "sidebar_meet",
            },
            {
                path: "/clarep",
                title: "Classroom",
                navTitle: "Classroom",
                icon: "area_chart",
                class: "",
                session_show: false,
                id: "sidebar_classroom",
            },
            {
                path: "/driveStorage",
                title: "Almacenamiento",
                navTitle: "Almacenamiento",
                icon: "data_usage",
                class: "",
                session_show: false,
                id: "sidebar_driveStorage",
            },
            {
                path: "/filters",
                title: "Filtros",
                navTitle: "Filtros",
                icon: "filter_list",
                class: "mt-4",
                session_show: false,
                id: "sidebar_filters",
            },
        ];

        const tmpNav = nav.map((val) => {
            if (this.check) {
                return val;
            } else {
                if (val.session_show) val.session_show = false;
                else val.session_show = true;

                return val;
            }
        });

        this.nav_show = tmpNav;
    }

    search(userinfo: any, result: any) {
        const db = this.auth.getDb();

        const user = userinfo.additionalUserInfo.profile;
        const id = userinfo.additionalUserInfo.profile.id;
        const credential = userinfo.credential;
        let domain = user.hd;
        const accessToken = credential.accessToken;
        const providerId = credential.providerId;
        const email = userinfo.additionalUserInfo.profile.email;

        if (domain) {
            domain = domain.split(".").join("_").toUpperCase();
            domain = domain.replace("-", "");
        } else {
            this.modal.title = "No autorizado";
            this.modal.body = `Estimado ${user.name}, el correo ${user.email} con el que intenta iniciar sesion no pertenece a un dominio institucional.`;
            return false;
        }

        let userDB = {
            email: user.email,
            firstName: user.given_name,
            lastName: user.family_name,
            domainSite: user.hd.toUpperCase(),
            domain: domain,
            name: user.name,
            picture: user.picture,
            id: id,
            role: "user",
            accessToken: accessToken,
            providerId: providerId,
        };

        const cAfter = {
            login: db.collection(this.collections.login).doc(sha1(email)),
            timeout: db.collection(this.collections.timeout).doc(domain),
            admin: db.collection(this.collections.admin).doc("Permit"),
        };

        // mogration users destiny
        const cBefore = {
            login: db.collection(`Domain/${domain}/Users`).doc(id),
            register: db
                .collection(`Domain/${domain}/Register`)
                .doc(userDB.email),
        };

        // login collection search
        const login = {
            after: (callback: any) => {
                const snapshot = cAfter.login.get();

                snapshot.subscribe((document: any) => {
                    const data = document.data();
                    if (document.exists) {
                        userDB.role = data["role"];
                        userDB.domain = data["domain"][0];
                        this.userInfo = userDB;
                        this.domains = data.domain;
                        callback(true);
                    } else callback(false);
                });
            },
            before: (callback: any) => {
                const snapshot = cBefore.login.get();

                snapshot.subscribe((document: any) => {
                    const data = document.data();

                    if (document.exists) {
                        userDB.role = data.role;
                        this.userInfo = userDB;
                        this.domains = [data.domain];
                        cAfter.login.set({
                            domain: [userDB.domain],
                            role: data.role,
                        });
                        callback(true);
                    } else callback(false);
                });
            },
        };
        // Register collection search
        const register = {
            before: (callback: any) => {
                const snapshot = cBefore.register.get();

                snapshot.subscribe((document: any) => {
                    if (document.exists) {
                        const data = document.data();
                        userDB.role = data.role;

                        cBefore.login.set(userDB);
                        cAfter.login.set({
                            domain: [userDB.domain],
                            role: data.role,
                        });
                        this.domains = [userDB.domain];
                        this.userInfo = userDB;
                        cBefore.register.delete();
                        // Activate Newsletter
                        const userUpdate = {
                            email: userDB["email"],
                            name: userDB["name"],
                        };
                        let tmp = new Object();
                        tmp[userDB["id"]] = userUpdate;
                        //Get collection
                        const collection = this.getDB()
                            .collection(this.collectionNewsletter)
                            .doc(userDB["domain"]);
                        collection.update(tmp).catch((err) => {
                            if (err.code == "not-found") collection.set(tmp);
                        });
                        callback(true);
                    } else {
                        this.modal.title = "Usuario no registrado";
                        this.modal.body = `Estimado ${userDB.name}, le informamos que su cuenta ${userDB.email} no esta registrada para nuestra plataforma, le pedimos de la manera mas atenta que contacte con su administrador de dominio ${userDB.domainSite} para otorgar permisos suficientes.`;
                        callback(false);
                    }
                });
            },
        };

        const superAdmin = (callback: any) => {
            const snapshot = cAfter.admin.get();

            snapshot.subscribe(
                (document: any) => {
                    if (document.exists) {
                        const domain = document.data()["Domain"];
                        this.domains = domain;
                        callback();
                    } else {
                        this.domains = [userDB.domain];
                        callback();
                    }
                },
                (/**Error*/) => {
                    this.domains = [userDB.domain];
                    callback();
                }
            );
        };

        // Timeout collection search
        // Callback false if exists
        const timeout = (callback: any) => {
            const snapshot = cAfter.timeout.get();

            snapshot.subscribe((document: any) => {
                if (document.exists) {
                    const times = document.data().timeout;
                    const dateNow = new Date().getTime();

                    if (times < dateNow) {
                        cAfter.timeout.delete().then();
                        callback(true);
                    } else {
                        this.modal.title = "Aún no puede iniciar sesión";
                        this.modal.body = `Estimado ${userDB.name}, Gracias por registrate en platmos, en un periodo de 24 horas personal de edvolution se pondrá en contacto contigo para compartirte siguientes pasos.`;
                        callback(false);
                    }
                } else callback(true);
            });
        };

        const logger = () => {
            this.logger.save("login:success", user.email, "login success");
        };

        timeout((tmTime: any) => {
            if (tmTime) {
                login.before((tmLoAf: any) => {
                    // console.log(tmLoAf)
                    if (tmLoAf) {
                        superAdmin(() => {
                            this.session = {
                                userInfo: this.userInfo,
                                domain: this.domains,
                            };
                            this.setSession(this.session);
                            this.auth.svSsn(this.session);
                            setTimeout(() => {
                                logger();
                                if (this.redirect == window.location.pathname)
                                    this.route.navigate([this.uris.HOME_LOGIN]);
                                else this.route.navigate([this.redirect]);
                            }, 2000);
                        });
                    } else {
                        register.before((tmLB: any) => {
                            if (tmLB) {
                                superAdmin(() => {
                                    this.session = {
                                        userInfo: this.userInfo,
                                        domain: this.domains,
                                    };
                                    this.setSession(this.session);
                                    this.auth.svSsn(this.session);
                                    setTimeout(() => {
                                        logger();
                                        if (
                                            this.redirect ==
                                            window.location.pathname
                                        )
                                            this.route.navigate([
                                                this.uris.HOME_LOGIN,
                                            ]);
                                        else
                                            this.route.navigate([
                                                this.redirect,
                                            ]);
                                    }, 2000);
                                });
                            } else {
                                login.after((tmRB: any) => {
                                    if (tmRB) {
                                        superAdmin(() => {
                                            this.session = {
                                                userInfo: this.userInfo,
                                                domain: this.domains,
                                            };
                                            this.setSession(this.session);
                                            this.auth.svSsn(this.session);
                                            setTimeout(() => {
                                                logger();
                                                if (
                                                    this.redirect ==
                                                    window.location.pathname
                                                )
                                                    this.route.navigate([
                                                        this.uris.HOME_LOGIN,
                                                    ]);
                                                else
                                                    this.route.navigate([
                                                        this.redirect,
                                                    ]);
                                            }, 2000);
                                        });
                                    } else {
                                        this.userTMP.name = userDB.name;
                                        this.userTMP.email = userDB.email;
                                        // this.cleanSession();
                                        result({ modal: this.modal });
                                    }
                                });
                            }
                        });
                    }
                });
            } else {
                result({ modal: this.modal });
            }
        });
    }

    recoveryDaysAvalilable() {
        const params = [`domain=${this.getDomain()}`];
        this.http.uri_serve = "URI_back";
        this.http
            .sGet("dates.available", params, this.credential)
            .then((ret) => {
                const days = ret["rows"][0];
                this.local.available_day.dateInit = days["dateInit"] || null;
                this.local.available_day.dateEnd = days["dateEnd"] || null;
            })
            .catch((err) => {
                console.error(err);
            });
    }

    private quickstartLoad(): void {
        const review = localStorage.session_token_analytics_quickstart;

        if (review) {
            if (!JSON.parse(review))
                $("#quickstart-cmp-modal")["modal"]("show");
        } else $("#quickstart-cmp-modal")["modal"]("show");
    }
}

