import { Component, OnInit } from "@angular/core";
import { SessionService } from "../services/session/session.service";
import { FiltersService } from "../pages/filters/filters.service";
import { LocalService } from "../services/local/local.service";

@Component({
    moduleId: module.id,
    selector: "sidebar-cmp",
    templateUrl: "sidebar.component.html",
})
export class SidebarComponent implements OnInit {
    public menuItems: any[];
    show = false;
    public userInfo: any;
    public picture = "";
    public filterShow = true;
    private routesFilterShow = ["use", "meet", "filters", "driveStorage"];

    constructor(
        public session: SessionService,
        public filters: FiltersService,
        public local: LocalService
    ) {
        // Load sidebar charger Observable
        this.session.componentMethodCalled$.subscribe(() => {
            this.loadNav();
        });
    }
    ngOnInit() {
        this.loadNav();
    }

    loadNav() {
        const path_location = window.location.pathname.replace("/", "");

        if (this.routesFilterShow.indexOf(path_location) >= 0)
            this.filterShow = true;
        else this.filterShow = false;

        this.filters.reloadFilters();
        this.show = this.session.check;
        this.menuItems = this.session.nav_show;
    }
}

