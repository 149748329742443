import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Main Component
import { ModalsComponent } from './modals.component';

@NgModule({
    imports: [
      CommonModule,
      FormsModule
    ],
    declarations: [ ModalsComponent ],
    exports: [ ModalsComponent ],
    providers: [ DatePipe ]
})

export class ModalsModule {}
