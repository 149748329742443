export enum gtmEvent {
  EVENT = "event",
  NAVIGATION = "page",
  START = "Start App"
}

export interface IAnalyticsGTMEvent {
  event?: gtmEvent;
  title?: string;
  name?: string;
  value?: string;
  page?: string;
  user_id?: string;
}
