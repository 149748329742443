import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
//import { AnyNsRecord } from 'dns';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private datePipe = new DatePipe('es-MX');

  constructor() { }

  dateTransform(date, format) {
    return this.datePipe.transform(date, format);
  }

  hrs_min(duration, format = null) {
    duration = parseInt(duration);

    const milliseconds = Math.floor(((duration % 1000) / 100));
    const seconds = Math.floor(((duration / 1000) % 60));
    const minutes = Math.floor(((duration / (1000 * 60)) % 60));
    const hours = Math.floor(((duration / (1000 * 60 * 60)) % 24));
    const days = Math.floor((duration / (1000 * 60 * 60)) / 24);

    const addZ = (n) => {
      return (n < 10 ? '0' : '') + n;
    }

    switch (format) {
      case "d":
        return `${days}`;
        break;
      case "hm":
        return `${addZ(hours)} h ${addZ(minutes)} min`;
        break;
      case 'hms':
        return `${addZ(hours)} h ${addZ(minutes)} min ${addZ(seconds)} s`;
        break;
      default:
        return `${addZ(hours)} h ${addZ(minutes)} min`;
    }
  }

  // *     example: number_format(1234.56, 2, ',', ' ');
  // *     return: '1 234,56'
  number_format(number, format, callback = (n) => { return n }) {
    const value = parseInt(number);

    if (typeof format === "boolean") {
      if (format) return callback(number);
    } else if (typeof format === "string") {
      switch (format) {
        case "hrs_min":
          return callback(number);
          break;
        case "percentage":
          return callback(number + " %");
        default:
          return ` ${number}`;
      }
    } else return ` ${number}`;
  }

  percenStr(input): String {
    return ` ${input} %`;
  }

  parseDomainName(input = ""): String {
    const tmp = input.split("_");
    return tmp.join(".").toUpperCase();
  }

  indexes(input=[], field, index) {
    let tmp = 0;

    for (let key = 0; input.length > key; key ++)
      if (index === input[key][field]) {
        tmp = key;
        return tmp;
      }

    return null;
  }


  percentageAdoption(object, d = false): any {
    //d = d || false;
    const res = {
      cbPercentage: null,
      drivePercentage: null,
      gmailPercentage: null,
      docsPercentage: null,
      classroomPercentage: null,
      lvl1Percentage: null,
      lvl2Percentage: null,
      trainInnoPercentage: null,
      trainInnoPercentageT: null
    };
    const tmp = {
      trainerCertificates: new Array(),
      grade: null,
      innovativeCertificates: new Array(),
      teachersTotal: null

    };

    if (typeof object.trainerCertificates === "undefined")
      tmp.trainerCertificates = [];
    else tmp.trainerCertificates = object.trainerCertificates;

    if (typeof object.grade === "undefined")
      tmp.grade = 0;
    else tmp.grade = object.grade;

    if (typeof object.innovativeCertificates === "undefined")
      tmp.innovativeCertificates = [];
    else tmp.innovativeCertificates = object.innovativeCertificates;

    if (typeof object.teachersTotal === "undefined" || object.teachersTotal === 0)
      tmp.teachersTotal = 1;
    else tmp.teachersTotal = object.teachersTotal;

    const pTI = () => {
      const t1 = tmp.teachersTotal % 100;
      let t2 = (tmp.teachersTotal - t1) / 100;
      if (t2 === 0) t2 = 1;

      const t3 = { p: t2 * 100 / tmp.teachersTotal, t: t2 };
      return t3;
    };

/*     if (typeof object.cbPercentage === "undefined" || d) // chromebooks
      res.cbPercentage = (d) ? 20 : (20 / 100);
    else res.cbPercentage = object.cbPercentage / 100; */

    if (typeof object.drivePercentage === "undefined" || d) // drive
      res.drivePercentage = (d) ? 50 : (50 / 100);
    else res.drivePercentage = object.drivePercentage / 100;

    if (typeof object.gmailPercentage === "undefined" || d) // gmail
      res.gmailPercentage = (d) ? 50 : (50 / 100);
    else res.gmailPercentage = object.gmailPercentage / 100;

    if (typeof object.docsPercentage === "undefined" || d) // docs
      res.docsPercentage = (d) ? 50 : (50 / 100);
    else res.docsPercentage = object.docsPercentage / 100;

    if (typeof object.classroomPercentage === "undefined" || d) // classroom
      res.classroomPercentage = (d) ? 50 : (50 / 100);
    else res.classroomPercentage = object.classroomPercentage / 100;

    if (typeof object.lvl1Percentage === "undefined" || d) // c-lvl 1
      res.lvl1Percentage = (d) ? 20 : (20 / 100);
    else res.lvl1Percentage = object.lvl1Percentage / 100;

    if (typeof object.lvl2Percentage === "undefined" || d) // c-lvl 2
      res.lvl2Percentage = (d) ? 5 : (5 / 100);
    else res.lvl2Percentage = object.lvl2Percentage / 100;

    const trInObj = pTI();

    if (typeof object.trainInnoPercentage === "undefined" || d) {// c - tr and inno
      res.trainInnoPercentage = (d) ? trInObj.p : (trInObj.p / 100);
      //res.trainInnoPercentage = (d) ? trInObj.p : parseFloat($filter("number")(trInObj.p / 100, 2));
      res.trainInnoPercentageT = trInObj.t;
    }
    else {
      res.trainInnoPercentageT = trInObj.t;
      res.trainInnoPercentage = trInObj.p / 100;

      // res.trainInnoPercentage = parseFloat($filter("number")(trInObj.p / 100, 2));
    }

    return res;
  };

  percentageCv(max = 1, pr) {
    let res = {
      Completo: null,
      Incompleto: null
    };
    res.Completo = Math.round((pr * 100) / max);
    const ms = 100 - res.Completo;
    res.Incompleto = 0;

    if (ms <= 0)
      res.Incompleto = 0;
    // else res.Incompleto = $filter("number")(ms);
    else res.Incompleto = ms;

    return res;
  }

  parseDeliveryDropdown(name) {
    return name.split(" ").join("_");
  }
}
