import { Injectable } from "@angular/core";
import { SessionService } from "../../services/session/session.service";
import { LocalService } from "../../services/local/local.service";
import { Router } from "@angular/router";
import { Subject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class FiltersService {
    // names filters
    public names = [];
    public objFilter = {};
    public active = "";
    public path = null;
    public objRender = {};

    private db = null;
    private fire = null;

    // Observable string sources
    private reloadDasObserve = new Subject<any>();
    reloadDasCalled$ = this.reloadDasObserve.asObservable();

    private reloadFiltersObserve = new Subject<any>();
    reloadFiltersCalled$ = this.reloadFiltersObserve.asObservable();

    private reloadDasMeetObserve = new Subject<any>();
    reloadDasMeetCalled$ = this.reloadDasMeetObserve.asObservable();

    private reloadDasStorageObserve = new Subject<any>();
    reloadDasStorageCalled$ = this.reloadDasStorageObserve.asObservable();

    constructor(
        private session: SessionService,
        private local: LocalService,
        private route: Router
    ) {
        this.db = session.getDB();
        this.fire = session.getFire();
    }

    reloadDash() {
        this.reloadDasObserve.next();
    }
    reloadFilters() {
        this.reloadFiltersObserve.next();
    }
    reloadDashMeet() {
        this.reloadDasMeetObserve.next();
    }
    reloadDashStorage() {
        this.reloadDasStorageObserve.next();
    }
    // ------------------------- Request in firestore db -----------------------
    // get all filters
    get() {
        const collection = this.db
            .collection(`Domain/${this.session.getDomain()}/Settings`)
            .doc("Filters");

        return collection.get();
    }

    // set filters
    set(obj) {
        const collection = this.db
            .collection(`Domain/${this.session.getDomain()}/Settings`)
            .doc("Filters");

        return collection.set(obj);
    }

    // update field in filters
    update(name, value) {
        const collection = this.db
            .collection(`Domain/${this.session.getDomain()}/Settings`)
            .doc("Filters");

        return collection.update({ [name]: value });
    }

    // delete document Filters
    delete() {
        const collection = this.db
            .collection(`Domain/${this.session.getDomain()}/Settings`)
            .doc("Filters");

        return collection.delete();
    }

    // delete file value in document filters
    deleteField(name, callback) {
        const collection = this.db
            .collection(`Domain/${this.session.getDomain()}/Settings`)
            .doc("Filters");

        if (callback) {
            collection
                .update({
                    [name]: this.fire.firestore.FieldValue.delete(),
                })
                .then(() => {
                    callback();
                })
                .catch((err) => {
                    console.log(err);
                });
        } else
            return collection.update({
                [name]: this.fire.firestore.FieldValue.delete(),
            });
    }

    // --------------------------- Action controller ---------------------------
    // show modal save filter
    showModal(modal = "save_view_modal") {
        $(`#${modal}`)["modal"]("show");
    }

    // hide modal
    hideModal(modal = "save_view_modal") {
        $(`#${modal}`)["modal"]("hide");
    }

    // load filters in dashboard
    loadDash(name = "") {
        console.log(name);
        this.local.dashboard.filter = this.objFilter[name];
        this.local.filter_apply = name;
        this.active = name;
        const path = this.path || window.location.pathname;
        this.route.navigate([path]);
        this.reloadDash();
        this.reloadDashMeet();
        this.reloadDashStorage();
    }

    // delete filter in dashboard
    eraserFilter() {
        this.local.dashboard.filter = {
            users: "",
            objUsersRes: [],
            lenUsers: 0,
            cb: "",
            objCbRes: [],
            lenCb: 0,
            org: "",
            objOrgRes: [],
            lenOrg: 0,
        };
        this.local.filter_apply = "";
        this.active = "";
        const path = window.location.pathname;
        this.route.navigate([path]);
        this.reloadDash();
        this.reloadDashMeet();
        this.reloadDashStorage();
    }
}

