import { Component, OnInit } from "@angular/core";
import { SessionService } from "../../services/session/session.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { SubscriptionsService } from "../../services/subscriptions/subscriptions.service";

@Component({
    moduleId: module.id,
    styleUrls: ["./quickstart.component.css"],
    selector: "quickstart-cmp",
    templateUrl: "quickstart.component.html",
})
export class QuickstartComponent implements OnInit {
    public title: any = [
        "Bienvenido a Analytics",
        "Información confiable",
        "Adopción digital",
        // "My Edvolution",
        "¡Descubre tus Meet!",
    ];
    public tabActive: number;
    public navQuickstart: any;
    private totalTab: number;

    constructor(private subscriptions: SubscriptionsService) {
        this.tabActive = 0;
        this.totalTab = this.title.length;
    }

    ngOnInit() {}

    next(): void {
        if (this.tabActive < this.totalTab - 1) this.tabActive++;
    }

    prev(): void {
        if (this.tabActive > 0) this.tabActive--;
    }

    save(): void {
        localStorage.session_token_analytics_quickstart = true;
    }
}

