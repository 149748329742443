import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UrisService } from '../uris/uris.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  public uri_serve = "";
  public uris: UrisService;
  private tokenMyEdvolution = "H4jULi3Qtr7YSW0Zl1GbGb3NV";
  private httpOptions = {
    headers: null,
    responseType: null
  }

  constructor(
    private http: HttpClient,
  ) {
    this.uris = new UrisService();
  }

  sPost(url: string, body=null): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      let uri = `${this.uris[this.uri_serve]}${url}`;
      const header = new HttpHeaders({
        'Content-Type': 'application/json'
      });

      this.http.post(uri, body, { headers: header })
      .subscribe(
        data => {
          resolve(data);
        }, err => {
          reject(err);
        }
      );
    });

    return promise;
  }

  sPut(url: string, body=null, myEd: boolean): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      let uri = `${this.uris[this.uri_serve]}${url}`;
      let options = this.httpOptions;

      options.headers =
        new HttpHeaders({
          'Content-Type': 'application/json'
        })

      if (myEd) {
        uri += `?token=${this.tokenMyEdvolution}`;
        options.responseType = "text" as const;
      }

      this.http.put(uri, body, options)
      .subscribe(
        data => {
          resolve(data);
        }, err => {
          reject(err);
        }
      );
    });

    return promise;
  }

  sGet(url: string, values=null, credential=null): Promise<any> {
    let params = "";

    if (values)
      params = values.join("&");

    const promise = new Promise((resolve, reject) => {
      let uri = `${this.uris[this.uri_serve]}${url}?`;
      const header = new HttpHeaders({
        'Content-Type': 'application/json'
      });

      if (credential === null) {
        uri = uri + params;
        this.http.get(uri, { headers: header })
        .subscribe(
          data => {
            resolve(data);
          }, err => {
            reject(err);
          }
        );
      } else {
        const crt = `token=${credential["token"]}&user=${credential["user"]}&`;
        uri = `${uri}${crt}${params}`;
        this.http.get(uri, { headers: header })
        .subscribe(
          data => {
            resolve(data);
          }, err => {
            reject(err);
          }
        );
      }

    });

    return promise;
  }
}
